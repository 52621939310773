module.exports = {
  messages: {
    ES: {
      errorTitle: "La página no existe",
      buttonTitle: "Página de inicio",
    },
    US: {
      errorTitle: "Sorry Wrong Page",
      buttonTitle: "Home page",
    }
  }
}
